import React from "react"
import { graphql } from "gatsby"
import {Layout} from "@components/Layout"
import {SEO, mapToSEO} from '@components/SEO'
import { ImageInformationList ,mapToInfoBannerContainer } from '@components/ImageInformationList'
import { mapToPageHeader, Banner } from "@components/Banner"
import { Grid } from "semantic-ui-react"
import { GenericBannerContainer } from "@components/GenericBannerContainer"
import { Paragraph1, H3, H2 } from "@styles/Global.styles"

interface Props{
  data: any;
}

class RoadSafetyLandingPage extends React.Component<Props>{

  render(){ 
    const {elements} = this.props.data.kontentItemRoadSafetyLandingPage;
    const {road_safety_items} = elements;
    const metadata = mapToPageHeader(elements);
    const seoData = mapToSEO(elements);
    const roadSafetyItems = mapToInfoBannerContainer(road_safety_items.linked_items[0].elements)

    return (<Layout version="standard">
                <SEO {...seoData} />
                <Banner {...metadata} isBiggerBanner={false} />
                <GenericBannerContainer 
                  padding={{
                    mobile: {
                        top: 60,
                        bottom: 80
                    },
                    desktop: {
                        top: 80,
                        bottom: 100
                    }
                  }}
                  backgroundColor={roadSafetyItems.backgroundColor}>
                    <Grid centered>
                        <Grid.Row>
                            <Grid.Column textAlign='center' mobile={16} tablet={13} computer={10}>
                                <img src={elements.main_heading_icon.value[0].url} alt="road safety"/>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center' mobile={16} tablet={13} computer={10}>
                                <H2>
                                    {elements.main_heading.value}
                                </H2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center' mobile={16} tablet={13} computer={10}>
                                <Paragraph1 color='#707070'>
                                    {elements.summary.value}
                                </Paragraph1>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column textAlign='center' mobile={16} tablet={13} computer={10}>
                                <H3>
                                    {elements.sub_heading.value}
                                </H3>
                                <GenericBannerContainer 
                                  padding={{
                                    mobile: {
                                        top: 20,
                                        bottom: 0
                                    },
                                    desktop: {
                                        top: 40,
                                        bottom: 0
                                    }
                                  }}
                                  backgroundColor='transparent'>
                                  <ImageInformationList 
                                    dividers='vertical'
                                    listItems={roadSafetyItems.listItems} 
                                    backgroundColor={roadSafetyItems.backgroundColor} 
                                    textAlign={roadSafetyItems.textAlign}
                                    columns={1} 
                                    horizontalLayout={false} 
                                    titleOverlayImage={false} 
                                    titleColor='#000000'
                                    textColor={roadSafetyItems.textColor} />
                                </GenericBannerContainer>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </GenericBannerContainer>
          </Layout>
      );
  }
}

export const query = graphql`
{
  site {
    siteMetadata {
      apiUrl
    }
  }
  kontentItemRoadSafetyLandingPage {
    elements {
      general_page_content__header_image {
        value {
          url
          description
        }
      }
      general_page_content__responsive_header_image {
        linked_items {
          ... on KontentItemBannerImage {
            id
            elements {
              desktop {
                value {
                  url
                  description
                }
              }
              mobile {
                value {
                  description
                  url
                }
              }
              tablet {
                value {
                  description
                  url
                }
              }
            }
          }
        }
      }
      general_page_content__kiwivip_signup {
        value {
          codename
          name
        }
      }
      general_page_content__kiwivip_signup_version {
        value {
          codename
          name
        }
      }
      general_page_content__title {
        value
      }
      general_page_content__title_position {
        value {
          codename
          name
        }
      }
      general_page_content__responsive_header_image {
        linked_items {
          ... on KontentItemBannerImage {
            id
            elements {
              desktop {
                value {
                  url
                  description
                }
              }
              mobile {
                value {
                  description
                  url
                }
              }
              tablet {
                value {
                  description
                  url
                }
              }
            }
          }
        }
      }
      main_heading {
        value
      }
      main_heading_icon {
        value {
          url
        }
      }
      road_safety_items {
        linked_items {
          ... on KontentItemInfoBannerContainer {
            elements {
              background_colors {
                value {
                  codename
                  name
                }
              }
              items {
                linked_items {
                  ... on KontentItemInfoBannerItem {
                    elements {
                      description {
                        value
                      }
                      icon {
                        value {
                          url
                        }
                      }
                      linktext {
                        value
                      }
                      linkurl {
                        value
                      }
                      title {
                        value
                      }
                    }
                  }
                }
              }
              text_alignment {
                value {
                  codename
                  name
                }
              }
              text_colors {
                value {
                  codename
                  name
                }
              }
            }
          }
        }
      }
      seo__nofollow {
        value {
          codename
          name
        }
      }
      seo__noindex {
        value {
          codename
          name
        }
      }
      seo__page_description {
        value
      }
      seo__page_title {
        value
      }
      sub_heading {
        value
      }
      summary {
        value
      }
      url {
        value
      }
    }
  }
}

  
`

export default RoadSafetyLandingPage
